import Dropzone from "react-dropzone";

import "../../styles/pages/pricesPage.css";
import { useEffect, useState } from "react";
import { CustomButton } from "../../components/customButton";
import { SelectList } from "../../components/selectList";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CLUSTERS } from "../../api/graphql/cluster";
import { ICluster } from "../../@types/graphql";
import { toast } from "sonner";
import { priceUpdate } from "../../api/rest/priceUpdate";
import { chosenStore } from "../../store/stores.store";
import { GET_PRICES } from "../../api/graphql/prices";
import { PricesTable } from "./table";
import {
  pricesChangedRows,
  chosenPricesCluster,
} from "../../store/prices.store";

export const PricesPage = () => {
  const storeId = useReactiveVar(chosenStore)?.id;
  const chosenCluster = useReactiveVar(chosenPricesCluster);
  const [{ formFile }, setOpts] = useState<{
    formFile?: File;
  }>({});
  const { data, loading } = useQuery<{ clusters: ICluster[] }>(GET_CLUSTERS, {
    fetchPolicy: "cache-and-network",
  });
  const { data: pricesData, refetch } = useQuery(GET_PRICES, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: { storeId: { eq: storeId }, clusterId: { eq: chosenCluster?.id } },
    },
    skip: !storeId || !chosenCluster?.id,
  });

  const clusters = data?.clusters
    ? data?.clusters?.map((i) => ({ ...i, value: i.id }))
    : [];

  const upload = async () => {
    if (!chosenCluster?.id) return toast.error("Вы не выбрали кластер");
    if (!storeId) return toast.error("Вы не выбрали магазин");

    try {
      const data = await priceUpdate({
        formFile,
        clusterId: chosenCluster.id,
        storeId,
      });

      if (data) {
        toast.success("Успешно загружено");
        pricesChangedRows({});
        setOpts((p) => ({ ...p, formFile: undefined }));
        refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    return () => {
      pricesChangedRows({});
    };
  }, []);

  return (
    <div className="prices-page">
      <div className="mb-24">
        <SelectList
          elements={clusters}
          active={clusters?.find((i) => i.id === chosenCluster?.id)}
          handleChange={(value) => chosenPricesCluster(value as any)}
          placeholder={
            loading ? "Загружаем кластеры......" : "Выберите кластер"
          }
        />
      </div>
      <Dropzone
        accept={{
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
        }}
        multiple={false}
        onDrop={(acceptedFiles) => {
          setOpts((p) => ({ ...p, formFile: acceptedFiles[0] }));
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <>
            {!formFile ? (
              <div
                className={`prices-dropzone prices-dropzone-drag-active__${isDragActive}`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <p>Перетащите файл или нажмите для выбора</p>
              </div>
            ) : (
              <div className={`prices-dropzone flex flex-col gap-12`}>
                {formFile.name}
                <div className="flex items-center gap-8">
                  <CustomButton
                    buttonView="outline"
                    onClick={() =>
                      setOpts((p) => ({ ...p, formFile: undefined }))
                    }
                  >
                    Отменить
                  </CustomButton>
                  <CustomButton onClick={upload}>Загрузить</CustomButton>
                </div>
              </div>
            )}
          </>
        )}
      </Dropzone>
      <div className="mt-24">
        <PricesTable prices={pricesData?.prices || []} />
      </div>
    </div>
  );
};
